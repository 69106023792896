export const userActions = ['Suspend User'];

export const validStatuses = [
  'Pre-qualification approved - Awaiting customer Authorization',
  'Pre-qualification declined',
  'Awaiting Delivery',
  'Awaiting customer signature',
  'Contract expired',
  'Awaiting customer signature',
  'Awaiting dealer signature',
  'Contract signed',
  'Contract funded',
  'Error',
  'Awaiting signatures',
  'Pending Manual Review',
  'Funded',
];

export const buildDealDisabledStatuses = [
  'dealer-finalised',
  'customer-reviewed',
  'prequal-manual-review',
  'prequal-review',
  'pending-review',
  'fund-complete',
];
