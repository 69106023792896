import { isAxiosError } from 'axios';
import { useCallback, useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import arrow from '@luna-protocol/core/src/assets/backArrow.svg';
import Body from '@luna-protocol/core/src/components/Body/Body';
import Button from '@luna-protocol/core/src/components/Button/Button';
import Card from '@luna-protocol/core/src/components/Card/Card.tsx';
import { FormatPhoneNumber, USDollar, formatDate, formatTime } from '@luna-protocol/core/src/utils/constants/format.ts';
import { useAuth } from '@luna-protocol/core/src/utils/useAuth.ts';
import useToast from '@luna-protocol/core/src/utils/useToast.ts';
import { AppContext } from '../../AppContext.js';
import { useGetDealStatus } from '../../queries/useGetDealStatus.ts';
import { postResendIdv } from '../../queries/usePostResendIdv.ts';
import useCustomerStatusCheck from '../../utils/hooks/useCustomerStatusCheck.ts';
import useBuildDealStatusCheck from '../../utils/hooks/useBuildDealStatusCheck.ts';
import UpdateBranding from '../UpdateBranding/UpdateBranding.tsx';
import messages from './DealStatus.messages.ts';
import styles from './DealStatus.module.scss';

const DealStatus = () => {
  useAuth();
  const { setLoanApplicationID, setCustomerName } = useContext(AppContext);
  const { loan_application_id } = useParams();
  const { data, isLoading = true, dataUpdatedAt } = useGetDealStatus(loan_application_id);
  const { formatMessage } = useIntl();
  const { createToast } = useToast();
  const navigate = useNavigate();
  const { checkStatus } = useCustomerStatusCheck();
  const { buildDealStatusCheck } = useBuildDealStatusCheck();
  const [searchParams] = useSearchParams();
  const [pending, setPending] = useState(false);

  const onResendIdentityVerificationClick = useCallback(() => {
    setPending(true);
    if (loan_application_id) {
      postResendIdv(loan_application_id)
        .then(() => {
          createToast({
            title: formatMessage(messages.resendIdentityVerificationSuccess),
            status: 'success',
          });
          setPending(false); // Stop the spinner on success
        })
        .catch(error => {
          setPending(false);
          if (isAxiosError(error)) {
            createToast({
              title: error.response?.data?.error,
              status: 'error',
            });
          }
        });
    }
  }, [createToast, loan_application_id, formatMessage]);

  const onBuildDealClick = useCallback(async () => {
    if (loan_application_id) {
      setLoanApplicationID(loan_application_id);
      setCustomerName(searchParams.get('customer_name') || '');
      navigate(`/amend_details`);
    }
  }, [loan_application_id, setLoanApplicationID, navigate, setCustomerName, searchParams]);

  if (isLoading) {
    return null;
  }

  const primaryApplicant = data?.applicants.find(applicant => applicant.is_primary);
  const coApplicant = data?.applicants.find(applicant => !applicant.is_primary);

  return (
    <Body fullWidth stretched>
      <div className={styles.dealStatus}>
        <UpdateBranding />
        <Link to="/prequalification" className={styles.backButton}>
          <img src={arrow} aria-label={formatMessage(messages.backButton)} alt={formatMessage(messages.backButton)} />
          {formatMessage(messages.backButton)}
        </Link>
        {data?.dealership_name && (
          <h1>
            {formatMessage(messages.dealerName)}: {data?.dealership_name}
          </h1>
        )}
        <div className={styles.spaceBetween}>
          <div>
            <span>{`${primaryApplicant?.first_name} ${primaryApplicant?.last_name}`}</span>
            {coApplicant && <span> // {`${coApplicant.first_name} ${coApplicant.last_name}`}</span>}
          </div>
          <div>
            {formatMessage(messages.lastUpdated, {
              date: formatDate(new Date(dataUpdatedAt).toISOString()),
              time: formatTime(new Date(dataUpdatedAt).toISOString()),
            })}
          </div>
        </div>
        <div>{formatMessage(messages.applicationDetails)}</div>
        <Card>
          <div>
            <table>
              <tbody>
                <tr>
                  <td>{formatMessage(messages.makeModel)}</td>
                  <td>
                    {data?.make && data.model && (
                      <>
                        {data?.make} {data?.model}
                      </>
                    )}
                  </td>
                </tr>

                <tr>
                  <td>{formatMessage(messages.preQualAmount)}</td>
                  <td>{data?.prequal_amount ? USDollar.format(data?.prequal_amount) : ''}</td>
                </tr>

                <tr>
                  <td>{formatMessage(messages.preQualDate)}</td>
                  <td>{data?.prequal_date ? formatDate(data.prequal_date) : ''}</td>
                </tr>

                <tr>
                  <td>{formatMessage(messages.unitAndContractDeliveryDate)}</td>
                  <td>{data?.delivery_date ? formatDate(data?.delivery_date) : ''}</td>
                </tr>
              </tbody>
            </table>
            <div className={styles.applicants}>
              <table className={coApplicant ? styles.showSeparator : ''}>
                <tbody>
                  <tr>
                    <td>{formatMessage(messages.primaryApplicant)}</td>
                    <td>{`${primaryApplicant?.first_name} ${primaryApplicant?.last_name}`}</td>
                  </tr>
                  <tr>
                    <td>{formatMessage(messages.status)}</td>
                    <td>
                      {data?.status === 'prequal-manual-review'
                        ? 'Pending Manual Review'
                        : data?.status
                          ? data.status
                          : ''}
                    </td>
                  </tr>

                  {primaryApplicant?.poi_complete === true && (
                    <tr>
                      <td>{formatMessage(messages.incomeVerification)}</td>
                      <td>{formatMessage(messages.verified)}</td>
                    </tr>
                  )}

                  {primaryApplicant?.idv_complete === true && (
                    <tr>
                      <td>{formatMessage(messages.identityVerification)}</td>
                      <td>{formatMessage(messages.verified)}</td>
                    </tr>
                  )}
                </tbody>
              </table>
              {coApplicant && (
                <>
                  <div className={styles.divider} />
                  <table>
                    <tbody>
                      <tr>
                        <td>{formatMessage(messages.coApplicant)}</td>
                        <td>{`${coApplicant.first_name} ${coApplicant.last_name}`}</td>
                      </tr>
                      <tr>
                        <td>{formatMessage(messages.status)}</td>
                        <td>
                          {data?.status === 'prequal-manual-review'
                            ? 'Pending Manual Review'
                            : data?.status
                              ? data.status
                              : ''}
                        </td>
                      </tr>

                      {coApplicant.poi_complete === true && (
                        <tr>
                          <td>{formatMessage(messages.incomeVerification)}</td>
                          <td>{formatMessage(messages.verified)}</td>
                        </tr>
                      )}

                      {coApplicant.idv_complete === true && (
                        <tr>
                          <td>{formatMessage(messages.identityVerification)}</td>
                          <td>{formatMessage(messages.verified)}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </>
              )}
            </div>
            {!data?.applicants.every(applicant => applicant.idv_complete) && (
              <div className={styles.resendIdentityVerification}>
                <Button
                  fullWidth={false}
                  variant="secondary"
                  onClick={onResendIdentityVerificationClick}
                  pending={pending}
                  disabled={data && checkStatus(data.status)}>
                  {formatMessage(messages.resendIdentityVerification)}
                </Button>
                <div>{formatMessage(messages.resendIdentityVerificationDisclaimer)}</div>
              </div>
            )}
          </div>
        </Card>
        <div>{formatMessage(messages.applicantDetails)}</div>
        <Card>
          <div className={styles.applicants}>
            <table className={coApplicant ? styles.showSeparator : ''}>
              <tbody>
                <tr>
                  <td>{formatMessage(messages.primaryApplicant)}</td>
                  <td>{`${primaryApplicant?.first_name} ${primaryApplicant?.last_name}`}</td>
                </tr>
                <tr>
                  <td>{formatMessage(messages.cellNumber)}</td>
                  <td>
                    {primaryApplicant?.cell_phone_number ? FormatPhoneNumber(primaryApplicant.cell_phone_number) : ''}
                  </td>
                </tr>
                <tr>
                  <td>{formatMessage(messages.state)}</td>
                  <td>{primaryApplicant?.state ? primaryApplicant.state : ''}</td>
                </tr>
                <tr>
                  <td>{formatMessage(messages.zip)}</td>
                  <td>{primaryApplicant?.zip_code ? primaryApplicant.zip_code : ''}</td>
                </tr>
              </tbody>
            </table>
            {coApplicant && (
              <>
                <div className={styles.divider} />
                <table>
                  <tbody>
                    <tr>
                      <td>{formatMessage(messages.coApplicant)}</td>
                      <td>{`${coApplicant.first_name} ${coApplicant.last_name} `}</td>
                    </tr>
                    <tr>
                      <td>{formatMessage(messages.cellNumber)}</td>
                      <td>{coApplicant?.cell_phone_number ? FormatPhoneNumber(coApplicant.cell_phone_number) : ''}</td>
                    </tr>
                    <tr>
                      <td>{formatMessage(messages.state)}</td>
                      <td>{coApplicant?.state ? coApplicant.state : ''}</td>
                    </tr>
                    <tr>
                      <td>{formatMessage(messages.zip)}</td>
                      <td>{coApplicant?.zip_code ? coApplicant.zip_code : ''}</td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}
          </div>
        </Card>

        <Button
          fullWidth={false}
          variant="secondary"
          onClick={onBuildDealClick}
          disabled={buildDealStatusCheck(data?.status ? data.status : '')}>
          {formatMessage(messages.buildDeal)}
        </Button>
      </div>
    </Body>
  );
};

export default DealStatus;
