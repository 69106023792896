import { useCallback } from 'react';
import { buildDealDisabledStatuses } from '../constants';

const useBuildDealStatusCheck = () => {
  const buildDealStatusCheck = useCallback((status: string) => {
    return buildDealDisabledStatuses.includes(status);
  }, []);

  return { buildDealStatusCheck };
};

export default useBuildDealStatusCheck;
