import {
  Column,
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { ReactNode, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import filterIcon from '../../assets/filterIcon.svg';
import sortSvg from '../../assets/sortUpAndDown.svg';
import sortIconAsc from '../../assets/sortUp.svg';
import sortIconDesc from '../../assets/sortDown.svg';
import ButtonGroup from '../ButtonGroup/ButtonGroup';
import messages from './DataTable.messages';
import './DataTable.scss';
import { Spinner } from '../Spinner/Spinner';
import LineBreak from '../LineBreak/LineBreak';
import Pagination from '../Pagination/Pagination';

interface DataTableProps {
  index?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  readonly?: boolean;
  disabledRanges?: number[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<any, any>[];
  isLoading?: boolean;
  buttons?: ReactNode[];
  updatedAt?: number;
  tableFor?: string;
  onRowClick?: (row: Row<unknown>) => void;
  currentPage?: number;
  total?: number;
  limit?: number;
  changePage?: (page: number) => void;
  // delete this boolean when pagination is implemented
  paginationEnabled?: boolean;
  sortedEnabled?: boolean;
  onSortingChange?: (state: SortingState) => void;
  customClasses?: string;
}

type HeaderGroupProps = {
  headers: unknown[];
  depth: number;
  id: string;
};

const DataTable = ({
  data,
  columns,
  isLoading,
  buttons,
  updatedAt,
  tableFor,
  onRowClick,
  paginationEnabled = false,
  currentPage,
  total,
  limit,
  changePage,
  sortedEnabled = false,
  onSortingChange = undefined,
  customClasses,
}: DataTableProps) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const { formatMessage } = useIntl();
  const [pageTotal, setPageTotal] = useState(0);

  useEffect(() => {
    setPageTotal(total || 0);
  }, [total]);

  useEffect(() => {
    if (sortedEnabled && onSortingChange) {
      onSortingChange(sorting);
    }
  }, [sorting]);

  const table = useReactTable({
    data,
    columns,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
      columnFilters,
    },
    manualSorting: sortedEnabled,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  return (
    <>
      <div className="table-screen">
        {updatedAt && updatedAt > 0 && (
          <div className="data-update-time" data-testid="updated-time">
            {tableFor ? tableFor : ''} {formatMessage(messages.updated, { date: new Date(updatedAt).toLocaleString() })}
          </div>
        )}

        {buttons && (
          <div className="table-buttons">
            <ButtonGroup align="right">
              {buttons.map((button, index) => (
                <div className="table-button" key={index}>
                  {button}
                </div>
              ))}
            </ButtonGroup>
          </div>
        )}
      </div>
      <table className={`table ${customClasses ? customClasses : ''}`}>
        <thead className="table-head">
          {table.getHeaderGroups().map((headerGroup: HeaderGroupProps) => (
            <tr className="table-row" key={headerGroup.id}>
              {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
              {headerGroup.headers.map((header: any) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    <div
                      {...{
                        className:
                          header.column.getCanSort() || header.column.getCanFilter()
                            ? 'cursor-pointer select-none'
                            : '',
                        onClick: header.column.getCanSort() ? header.column.getToggleSortingHandler() : undefined,
                      }}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      {header.column.getCanSort() ? (
                        ({
                          asc: <img className="table-icon" src={sortIconAsc} alt="sortIconAsc" />,
                          desc: <img className="table-icon" src={sortIconDesc} alt="sortIconDesc" />,
                        }[header.column.getIsSorted() as string] ?? (
                          <img className="table-icon" src={sortSvg} alt="sortIconAsc" />
                        ))
                      ) : header.column.getCanFilter() ? (
                        <ColumnFilter column={header.column} />
                      ) : null}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody className="table-body">
          {isLoading && (
            <tr>
              <td colSpan={columns.length} className="no-data">
                <LineBreak largeMargin />
                <Spinner color={'black'} tablespinner={true} />
              </td>
            </tr>
          )}
          {!isLoading && (!data || data.length === undefined || data.length === 0) ? (
            <tr>
              <td colSpan={columns.length} className="no-data">
                {formatMessage(messages.noData)}
              </td>
            </tr>
          ) : (
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            table.getRowModel()?.rows.map((row: any) => {
              return (
                <tr
                  key={row.id}
                  onClick={() => (onRowClick ? onRowClick(row.original) : undefined)}
                  className={(onRowClick && 'clickable') || ''}>
                  {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                  {row.getVisibleCells().map((cell: any) => (
                    <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                  ))}
                </tr>
              );
            })
          )}
        </tbody>
      </table>

      {/* delete this boolean when pagination is default */}
      {paginationEnabled && (
        <Pagination
          total={pageTotal}
          limit={limit}
          page={currentPage}
          changePage={page => changePage && changePage(page)}
        />
      )}
    </>
  );
};

const ColumnFilter = ({ column }: { column: Column<unknown> }) => {
  const [show, setShow] = useState(false);
  const columnFilterValue = column.getFilterValue();
  const sortedUniqueValues = Array.from(column.getFacetedUniqueValues().keys()).sort();

  const toggleShow = (prev: boolean) => {
    setShow(!prev);
  };

  return (
    <>
      <img className="table-icon" src={filterIcon} alt="filterIcon" onClick={() => toggleShow(show)} />
      {show && (
        <>
          <datalist id={column.id + 'list'}>
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            {sortedUniqueValues.slice(0, 5000).map((value: any) => (
              <option value={value} key={value} />
            ))}
          </datalist>
          <DebouncedInput
            type="text"
            value={(columnFilterValue ?? '') as string}
            onChange={value => column.setFilterValue(value)}
            className="filter-input"
            list={column.id + 'list'}
          />
        </>
      )}
    </>
  );
};

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [value]);

  return <input {...props} value={value} onChange={e => setValue(e.target.value)} />;
}

export default DataTable;
